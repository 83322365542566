<template>
  <div
    class="app-toggle"
    :class="{
      'app-toggle--invert-label-position': invertLabelPosition,
      'app-toggle--disabled': disabled
    }"
  >
    <input
      :id="id"
      type="checkbox"
      :disabled="disabled"
      :checked="!!props.modelValue"
      class="app-toggle__checkbox sr-only"
      :class="{ 'app-toggle__checkbox--checked': !!props.modelValue }"
      :value="!!props.modelValue"
      :name="props.name"
      @input="$emit('update:modelValue', !!$event.target.checked)"
    >
    <label
      :for="id"
      class="app-toggle__toggle"
    >
      <span
        v-if="label"
        class="app-toggle__label"
        v-html="label"
      />
    </label>
  </div>
</template>

<script setup>
  const props = defineProps({
    id: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    invertLabelPosition: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  })

  defineEmits([
    'update:modelValue',
  ])
</script>

<style lang="scss" scoped>
  .app-toggle {
    --app-toggle-width: 40px;
    --app-toggle-height: 22px;
    --app-toggle-border-width: 2px;
    --app-toggle-border-radius: 12px;
    --app-toggle-bullet-dimensions: calc(var(--app-toggle-height) - 2 * var(--app-toggle-border-width));

    position: relative;
    margin: var(--spacing-small) 0;
    min-height: 22px;
  }

  .app-toggle--disabled {
    opacity: 0.7;
  }

  .app-toggle__toggle {
    position: relative;
    padding-left: var(--app-toggle-width);
    background-color: transparent;
    line-height: var(--app-toggle-line-height, var(--font-size-small));
    border-radius: var(--app-toggle-border-radius);
    display: flex;
    align-items: center;
  }

  .app-toggle--invert-label-position .app-toggle__toggle {
    padding-right: var(--app-toggle-width);
    padding-left: 0;
  }

  .app-toggle__toggle::before,
  .app-toggle__toggle::after {
    content: '';
    position: absolute;
  }

  .app-toggle__toggle::before {
    top: 0;
    left: 0;
    bottom: 0;
    width: var(--app-toggle-width);
    height: var(--app-toggle-height);
    border: var(--app-toggle-border-width) solid var(--color-light-gray);
    border-radius: var(--app-toggle-border-radius);
    background-color: var(--color-lightest);
    transition: background-color .15s ease-in-out, border .15s ease-in-out;
  }

  .app-toggle--invert-label-position .app-toggle__toggle::before {
    right: 0;
    left: auto;
  }

  .app-toggle__toggle::after {
    top: var(--app-toggle-border-width);
    left: var(--app-toggle-border-width);
    bottom: 0;
    width: var(--app-toggle-bullet-dimensions);
    height: var(--app-toggle-bullet-dimensions);
    border-radius: 50%;
    background-color: var(--color-lightest);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .25);
    transform: translateX(0);
    transition: transform .15s ease-in-out;
  }

  .app-toggle--invert-label-position .app-toggle__toggle::after {
    right: calc(var(--app-toggle-bullet-dimensions) + var(--app-toggle-border-width));
    left: auto;
  }

  .app-toggle__checkbox--checked + .app-toggle__toggle::before {
    border: var(--app-toggle-border-width) solid var(--color-secondary);
    background-color: var(--color-secondary);
  }

  .app-toggle__checkbox--checked + .app-toggle__toggle::after {
    transform: translateX(100%);
  }

  .app-toggle__label {
    position: relative;
    top: 1px;
    margin-left: var(--spacing-smaller);
    font-size: var(--font-size-small);
    line-height: 20px;
    color: var(--color-mine-shaft);
  }

  .app-toggle--invert-label-position .app-toggle__label {
    margin-right: var(--spacing-smaller);
    margin-left: 0;
  }
</style>
